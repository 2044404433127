export default class UserSitesTable {
    constructor() {
        this.table = $('#sites-table');
        this.site_select = $('.user-site-attach-select');
        this.user_sites = this.table.find('input[name="site_ids[]"]').map(function () {
            return parseInt($(this).val());
        }).toArray();
        this.initControls();
    }

    initControls() {
        this.site_select.select2({
            allowClear: true,
            ajax: {
                url: '/admin/sites/select-options',
                dataType: 'json',
                data: (params) => {
                    return {
                        query: params.term || '',
                        page: params.page || 1,
                        exclude: this.user_sites,
                    };
                }
            }
        });

        this.site_select.on('select2:select', (e) => {
            let id = $(e.target).val();
            this.attachSite(id);
            $(e.target).val(null).trigger('change');
        });

        this.table.on('click', '.js-user-unbind-site', (e) => {
            let id = $(e.target).data('site-id');
            this.detachSite(id);
        });
    }

    detachSite(site_id) {
        $('.js-user-sites-table-row[data-site-id=' + site_id + ']').remove();
        this.user_sites = this.user_sites.filter(function (item) {
            return item !== site_id
        })
    }

    attachSite(site_id) {
        if (!this.user_sites.includes(site_id)) {
            this.user_sites.push(parseInt(site_id));
            $.get('/admin/sites/' + site_id + '/admin-row', function (html) {
                $('#sites-table tbody').append(html);
            });
        }
    }

}
