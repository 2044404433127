export default class SiteUsersTable {
    constructor() {
        this.table = $('#users-table');
        this.user_select = $('.site-user-attach-select');
        this.site_users = this.table.find('input[name="user_ids[]"]').map(function() {
            return parseInt($(this).val());
        }).toArray()
        this.initControls();
    }

    initControls() {
        this.user_select.select2({
            allowClear: true,
            ajax: {
                url: '/admin/users/select-options',
                dataType: 'json',
                data: (params) => {
                    console.log(this.site_users);
                    return {
                        query: params.term,
                        page: params.page,
                        exclude: this.site_users,
                    };
                }
            }
        });

        this.user_select.on('select2:select', (e) => {
            let id = $(e.target).val();
            this.attachUser(id);
            $(e.target).val(null).trigger('change');
        });

        this.table.on('click', '.js-site-unbind-user', (e) => {
            let id = $(e.target).data('user-id');
            this.detachUser(id);
        });
    }

    detachUser(user_id) {
        $('.js-site-users-table-row[data-user-id=' + user_id + ']').remove();
        this.site_users = this.site_users.filter(function(item) {
            return item !== user_id
        })
        console.log(this.site_users)
    }

    attachUser(user_id) {
        if(!this.site_users.includes(user_id)) {
            this.site_users.push(parseInt(user_id));
            $.get('/admin/users/' + user_id + '/admin-row', function (html) {
                $('#users-table tbody').append(html);
            });
        }
    }

}
