$('.filter-select').select2({
    width: '100%',
    minimumResultsForSearch: Infinity
});

$('.js-filter-form :input').change(function() {
    let others = $(this).parents('.js-filter-form').find(':input').not('[type="submit"]').not(this);
    if ($(this).val()) {
        others.attr('disabled', 'disabled');
        others.val('');
    } else {
        others.removeAttr('disabled');
    }
});

$('.js-filter-form').submit(function() {
    action = $(this).attr('action');
    params = new URLSearchParams($(this).serialize());
    let allowedEntries = [];

    for(let entry of params.entries()) {
        if (entry[1] !== '') {
            allowedEntries.push(entry[0] + '=' + entry[1]);
        }
    }

    if (allowedEntries.length > 0) {
        window.location.replace(action + '?' + allowedEntries.join('&'));
    } else {
        window.location.replace(action);
    }

    return false;
});

