$ = global.$ = global.jQuery = require('jquery');
global.moment = require('moment');
global.lz = require("lzutf8");
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

require('./bootstrap');
require('jquery-datetimepicker');
require('admin-lte');
require('select2');

import SiteAdminsTable from './admin/site_users_table';
import UserSitesTable from './admin/user_sites_table';
import DisclaimerPopup from './admin/disclaimer-popup';

$( document ).ready(function() {
    require('./admin/filters');
    require('./admin/forms');
    require('./admin/tables');
    new SiteAdminsTable();
    new UserSitesTable();
    const disclaimer = new DisclaimerPopup();
    disclaimer.showOnce();

    global.$.datetimepicker.setLocale('ru');

    let dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown'))
    let dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
        return new bootstrap.Dropdown(dropdownToggleEl, {
            boundary: document.querySelector('body'),
            popperConfig: function () {
                return { strategy: "fixed"};
            }
        })
    })

    document.querySelectorAll('.admin-editor:not(.textareaInit)').forEach((node, index) => {
        $(node).addClass('textareaInit');

        ClassicEditor
            .create(node,
                {
                    language: 'ru',
                    // ckfinder: {
                    //     // The URL that the images are uploaded to.
                    //     uploadUrl: '/admin/ckeditor/upload?_token=' + $('meta[name="csrf-token"]').attr('content'),
                    // },
                })
            .catch(error => {
                console.error(error);
            });
    });

});
