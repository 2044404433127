$('.form-check-input--site-active-update').change(function () {
    let value = +$(this).prop('checked');
    let id = parseInt($(this).attr('data-id'));

    $.ajax({
        url: "/admin/sites/active_update",
        type: "POST",
        headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
        data: {
            id: id,
            active: value
        },
        success: function (response) {
            console.log(response);
        },
        error: function (response) {
            console.log(response);
        },
    });
});


$('.form-check-input--user-active-update').change(function () {
    let value = +$(this).prop('checked');
    let id = parseInt($(this).attr('data-id'));

    $.ajax({
        url: "/admin/users/active_update",
        type: "POST",
        headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
        data: {
            id: id,
            active: value
        },
        success: function (response) {
            console.log(response);
        },
        error: function (response) {
            console.log(response);
        },
    });
});

$('.form-check-input--admin-active-update').change(function () {
    let value = +$(this).prop('checked');
    let id = parseInt($(this).attr('data-id'));

    $.ajax({
        url: "/admin/admins/active_update",
        type: "POST",
        headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
        data: {
            id: id,
            active: value
        },
        success: function (response) {
            console.log(response);
        },
        error: function (response) {
            console.log(response);
        },
    });
});

$('.form-check-input--site-sample-active-update').change(function () {
    let value = +$(this).prop('checked');
    let id = parseInt($(this).attr('data-id'));

    $.ajax({
        url: "/admin/site-samples/active_update",
        type: "POST",
        headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
        data: {
            id: id,
            active: value
        },
        success: function (response) {
            console.log(response);
        },
        error: function (response) {
            console.log(response);
        },
    });
});


$('.table-order').click()

