$('.js-datepicker').datetimepicker({
    format: 'd.m.Y H:i',
    sideBySide: true,
});

$('.js-user-ajax-select').select2({
    allowClear: true,
    placeholder: 'Пользователь',
    ajax: {
        url: '/admin/users/select-options',
        dataType: 'json',
        data: function (params) {
            return {
                query: params.term,
                page: params.page,
            };
        }
    }
});

$('.js-site-ajax-select').select2({
    allowClear: true,
    placeholder: 'Сайт',
    ajax: {
        url: '/admin/sites/select-options',
        dataType: 'json',
        data: function (params) {
            return {
                query: params.term,
                page: params.page,
            };
        }
    }
})

$('.js-site-ajax-select, .js-user-ajax-select').on('select2:select', function () {
    $(this).addClass('selected')
});

$('.js-site-ajax-select, .js-user-ajax-select').on('select2:unselect', function () {
    $(this).removeClass('selected')
});

$('#organisation_category_id').on('change', function () {
    if($(this).val() == 1){
       $('.js-organisation-sub-category-id').show()
    }
    else{
        $('.js-organisation-sub-category-id').hide()
    }
});

$('.js-organisation-category-id').on('change', function () {
    if($(this).val().includes('1')){
        $('.js-organisation-sub-category-id').show()
    }
    else{
        $('.js-organisation-sub-category-id').hide()
    }
});


if ($(':input[name="metric"]').val()) {
    $(':input[name="metric_decoded"]').val(lz.decompress(lz.decodeBase64($(':input[name="metric"]').val())));
}

$(':input[name="metric_decoded"]').change(function () {
    $(':input[name="metric"]').val(lz.encodeBase64(lz.compress($(this).val())));
});

$('.js-search-select').select2();

$('.password-input-group__toggle-visible').click(function () {
    let input = $(this).siblings('.js-password-input-group__input');
    if (input.attr('type') === 'password') {
        input.attr('type', 'text')
        $(this).find('i.sv-icon').removeClass('icon-eye').addClass('icon-hide');
    } else {
        input.attr('type', 'password')
        $(this).find('i.sv-icon').removeClass('icon-hide').addClass('icon-eye');
    }
});

$('.js-password-input-group__generate').click(function () {
    let input = $('.js-password-input-group__input');
    let minLength = input.data('min-length');
    let password = window.crypto.getRandomValues(new BigUint64Array(4)).reduce(
        (prev, curr, index) => (
            !index ? prev : prev.toString(36)
        ) + (
            index % 2 ? curr.toString(36).toUpperCase() : curr.toString(36)
        )
    ).split('').sort(() => 128 -
        window.crypto.getRandomValues(new Uint8Array(1))[0]
    ).join('').slice(0, minLength);
    input.val(password);
    if (input.attr('type') === 'password') {
        input.attr('type', 'text')
        input.siblings('.password-input-group__toggle-visible').find('i.far').removeClass('far').addClass('fas');
    }
});

if ($('[data-banners-types]').length && $('[data-conditional-field]').length) {

    const banner_types = $('[data-banners-types]').data('banners-types');
    showConditionalFields(banner_types[$('.js-banner-type-select').val()]);

    $('.js-banner-type-select').change(function () {
        showConditionalFields(banner_types[$(this).val()]);
    });
}

function showConditionalFields(fields) {
    $('[data-conditional-field]').hide();

    fields.forEach(function (field) {
        $('[data-conditional-field=' + field + ']').show();
    });
}

